import React, { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { graphql, useStaticQuery } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import RecipePreviewGrid from '../components/recipe-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero-landing'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import styled from 'styled-components'
import PageTitle from '../components/page-title'
import { device } from '../styles/MediaQueries'
import { colors } from '../styles/Variables'
import { typography, PageHeroTitle, Paragraph, Heading1, Heading2, Heading3 } from '../styles/Typography'

gsap.registerPlugin(ScrollTrigger, SplitText)

export const query = graphql`
  query RecipesPageQuery {
    recipes: allSanityRecipe(limit: 12, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    desktop: file(relativePath: { eq: "recipes-bg.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
  }
`

const RecipesPage = props => {
  const titleRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline()
      .to(titleRef.current, {
        autoAlpha: 1,
        delay: 0.5
      })
  })

  const { data, errors, transitionStatus, entry, exit } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const recipeNodes = data && data.recipes && mapEdgesToNodes(data.recipes).filter(filterOutDocsWithoutSlugs)
  const backgroundImageData = data && data.desktop.childImageSharp.fluid

  return (
    <>
      <SEO title='Recipes' />
      <Hero imageData={backgroundImageData}>
        All Recipes
      </Hero>
      <Container>
        {/* <h1 className={responsiveTitle1}>Recipes</h1> */}
        <PageTitle ref={titleRef}>A selection of meal ideas inspired by the seasons.</PageTitle>
        {recipeNodes && recipeNodes.length > 0 && <RecipePreviewGrid nodes={recipeNodes} />}
      </Container>
    </>
  )
}

export default RecipesPage
